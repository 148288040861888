<template>
  <v-app>
    <Snackbar />
    <router-view />
  </v-app>
</template>

<script>
import Snackbar from "@/components/ui/Snackbar";
// import { get } from "lodash";

export default {
  name: "App",
    components: {
    Snackbar
  },


  computed: {
    
    // isAuthRequired() {
    //   return this.$route.matched.some((route) =>
    //     get(route, "meta.requiresAuth", false)
    //   );
    // },
  },
};
</script>
